import type { NextPage } from 'next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CompanyLogo } from 'ui/components/company-logo';

const Home: NextPage = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={12} sx={{ svg: { width: '100px' } }}>
        <CompanyLogo />
        <Typography variant="h3" gutterBottom>
          Cotizador
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Home;
